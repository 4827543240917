import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { FormattedMessage, Link } from "gatsby-plugin-intl";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import FrontHeaderImg from "../images/front-header.jpg";
import NewsItemCard from "../components/News/NewsCard";
import ProjectItemCard from "../components/Projects/ProjectCard";
import About from "../components/About/About";
import middleheader from "../images/middle-header.jpg";
import Members from "../components/Members";
import PartnerBanner from "../components/PartnerBanner";

const NL_DESCRIPTION =
  "Maya Universe Academy is een sociale beweging die goed en inclusief onderwijs toegankelijk maakt voor kinderen in Nepal. Als Nederlandse stichting supporten wij Maya met verschillende projecten. Wij geloven dat goed onderwijs bijdraagt aan positieve sociale verandering.";
const EN_DESCRIPTION =
  "Maya Universe Academy is a social movement that provides children in Nepal with access to good and inclusive education. As a Dutch foundation we support Maya with various projects. We believe that good education contributes to positive social change.";

function Landing({ pageContext, location }) {
  const { newsItems, projects, mayaHome, intl, vision } = pageContext;
  return (
    <Layout path={location.pathname}>
      <Helmet
        title={config.siteTitle}
        description={intl.language === "nl" ? NL_DESCRIPTION : EN_DESCRIPTION}
      />
      <SEO />
      <FrontHeader>
        <p>
          Join our community <br />
          to support quality education
        </p>
      </FrontHeader>
      {/* <CardContainer>

      <KathmantourBanner />
      </CardContainer> */}
      
      <CardContainer>
        <SubHeader>
          <FormattedMessage id="projects" defaultMessage="Projecten" />
        </SubHeader>
        {/* <SubText>
          {" "}
          <FormattedMessage
            id="supportGrowth"
            defaultMessage="Hoe wij vanuit Nederland bijdragen aan de groei van Maya"
          />
        </SubText> */}

        <NewsCardsHome>
          <ProjectItemCard
            headerPicture={mayaHome.headerPhoto}
            title={mayaHome.title}
            summary={intl.messages.mayaHomeIntro}
            isMayaHome
          />
          {projects.slice(0, 2).map((project, index) => (
            <ProjectItemCard
              key={project.id}
              even={index % 2 === 0}
              {...project}
            />
          ))}
        </NewsCardsHome>
        <MoreItems to={`/projects/`}>
          <FormattedMessage id="moreProjects" defaultMessage="Meer projecten" />
        </MoreItems>
      </CardContainer>
      <About summary={vision.summary} />
      <CardContainer>
        <SubHeader>
          <FormattedMessage id="blog" defaultMessage="Nieuws" />
        </SubHeader>
        {/* <SubText>
          <FormattedMessage
            id="lastUpdates"
            defaultMessage="De laatste updates en nieuwsberichten over Maya"
          />
        </SubText> */}
        {
          <NewsCardsHome>
            {newsItems.slice(0, 3).map((newsItem, index) => (
              <NewsItemCard
                key={newsItem.id}
                even={index % 2 === 0}
                {...newsItem}
              />
            ))}
          </NewsCardsHome>
        }
        <MoreItems to={`/blog/`}>
          <FormattedMessage id="moreNews" defaultMessage="Bekijk meer nieuws" />
        </MoreItems>
      </CardContainer>
      <MiddleHeader>
        <img src={middleheader} alt="overons" />
      </MiddleHeader>
      <PartnerBanner />
    </Layout>
  );
}

const SubText = styled.h2`
  display: inline-block;
  width: 600px;
  height: 22px;
  margin-top: 0px;
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-top: 5px;
  color: #666666;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 300px;
  }
`;

const MiddleHeader = styled.div`
  width: 100%;
  height: 435px;
  img {
    margin: 0;
    float: right;
    height: 100%;
    width: 100%;
    padding: 0px;
    object-fit: cover;
  }

  @media only screen and (min-width: 275px) and (max-width: 480px) {
    display: none;

    img {
      margin: 0;
      float: right;
      height: 100%;
      width: 100%;
      padding: 0px;
      object-fit: cover;
    }
  }
`;

const FrontHeader = styled.div`
  max-width: 100%;
  width: 100%;
  height: 458px;
  background: url(${FrontHeaderImg}) no-repeat center center;
  background-size: cover;
  text-align: center;
  position: relative;
  transform: scale(1);
  overflow: hidden;
  background-position: 50% 20%;
  p {
    width: 795px;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    margin-bottom: 243px;
    transform: translate(-50%, -50%);
    font-family: "Nunito Sans";
    font-size: 48px;
    font-weight: 600;
    line-height: 1.07;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    background-size: cover;
    text-align: center;
    position: relative;
    p {
      width: 330px;
      height: 50px;
      font-family: "Nunito Sans";
      font-size: 24px;
      font-weight: 600;
      line-height: 1.04;
      letter-spacing: 0;
    }
  }
`;

const NewsCardsHome = styled.div`
  width: 1200px;
  display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
    padding: 20px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const MoreItems = styled(Link)`
  margin-top: 55px;
  width: 100%;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 600;
  color: #3b89ca;
  letter-spacing: 0;
  text-align: center;
  align-self: center;
  margin-bottom: 0px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
    padding: 40px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 298px;
  height: 33px;
  background-size: cover;
  font-family: "Nunito Sans";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  color: #333333;
`;

const SubHeader2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 33px;
  background-size: cover;
  font-family: "Nunito Sans";
  font-size: 32px;
  font-weight: 600;
  margin-top: 30px;
  letter-spacing: 0;
  color: #333333;
`;

export default Landing;
