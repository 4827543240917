export default [
  {
    name: "HALlogo-site",
    link: "https://www.amsterdamslyceum.nl/",
  },
  {
    name: "wilde-ganzen",
    link: "https://www.wildeganzen.nl/",
  },
  {
    name: "haella",
    link: "https://www.haella.nl/",
  },
  {
    name: "hofsteestichting",
    link: "http://www.hofsteestichting.nl/",
  },
  {
    name: "NFNLogo",
    link: "https://nepalfederatie.org/",
  },
  {
    name: "logoMUfinal",
    link: "https://mayauniverseacademy.org/",
  },
];
