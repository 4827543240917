import React from "react";
import { FormattedMessage, injectIntl, Link } from "gatsby-plugin-intl";
import { compose } from "redux";
import { Img } from "react-image-loading";
import styled from "styled-components";
import slugify from "slugify";

const NewsItemCard = (props) => {
  const { title, summary, headerPicture, even } = props;
  return (
    <Container even={even} to={`/blog/${slugify(title, { lower: true })}`}>
      <StyledImg src={headerPicture} alt={title} />

      <InnerContainer>
        <CardTitle>
          <b>{title}</b>
        </CardTitle>

        <CardSummary>{summary}</CardSummary>
      </InnerContainer>
    </Container>
  );
};

const Container = styled(Link)`
  width: 355px;
  display: flex;
  background-color: #e9f2fa;
  margin-top: 40px;
  border-radius: 3px;
  position: relative;
  flex-direction: column;
  text-decoration: none;
  @media only screen and (min-width: 275px) and (max-width: 1100px) {
    width: 100%;
  }
  box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
`;

const StyledImg = styled(Img)`
  width: 100%;
  max-height: 200px;
  height: 200px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: transparent;
  overflow: hidden;
  margin-bottom: 0px;
  object-fit: cover;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px;
`;

const CardSummary = styled.p`
  margin-left: 0px;
  margin-top: 5px;
  font-family: "Nunito Sans";
  font-size: 16px;
  letter-spacing: 0;
  padding-right: 15px;
  padding-bottom: 15px;
  text-align: left;
  min-height: 150px;
  max-height: 150px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 7;
  line-clamp: 7;
  color: #333333;
  white-space: normal;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;


const CardTitle = styled.h4`
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
  height: 50px;
  -webkit-line-clamp: 2;
  display: inline-block;
`;




export default compose(injectIntl)(NewsItemCard);
