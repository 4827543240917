import React from "react";
import styled from "styled-components";
import ReactCardFlip from "react-card-flip";
import { useState } from "react";
import { Heart } from "react-awesome-spinners";

const styles = {
  slickActive: {
    width: "17.1px",
    height: "17.1px",
    backgroundColor: "#333333",
    borderRadius: "50%",
    zIndex: 3,
    marginLeft: "10px",
  },
  slickNon: {
    width: "17.1px",
    height: "17.1px",
    backgroundColor: "#ffffff",
    color: "#ffffff",
    borderRadius: "50%",
    zIndex: 3,
    marginLeft: "10px",
    fontSize: "0px",
    opacity: 1,
  },
  slickDots: {
    bottom: "50px",
    fontSize: "0px",
    width: "95%",
  },
};

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        dots: true,
      },
    },
  ],
};

const Members = ({ memberData }) => {
  const [flipped, setFlipped] = useState(-1);   
  return (
    <Container>
      <InnerContainer>
        <Title>{memberData.title}</Title>
        <Text>
          <span dangerouslySetInnerHTML={{ __html: memberData.introText }} />
        </Text>
        <MemberContainer>
          {memberData.members.map((item, index) => (
              <>
                {index === 1 && (
                  <SliderItem>
                    <SmallTitle>Contact</SmallTitle>
                    <Name>stichting@mua-nl.org</Name>
                    <Name>Haarlemmerweg 79C</Name>
                    <Name>1051KS Amsterdam Nederland</Name>
                    <Name>NL34 RABO 0311 6538 47</Name>
                  </SliderItem>
                )}
                <ReactCardFlip
                  flipDirection="horizontal"
                  isFlipped={flipped === index}
                >
                  <SliderItem
                    key={index}
                    even={index % 2 === 0}
                    onMouseEnter={() => setFlipped(index)}
                    onClick={() => setFlipped(index)}
                  >
                    <Picture src={item.picture} />
                    <Name>{item.name}</Name>
                    <TeamFunction>{item.function}</TeamFunction>
                  </SliderItem>
                  <FlippedItem onMouseLeave={() => setFlipped(-1)}>
                    {item.name === "Jesse van der Heijden" ? (
                      <HearthContainer>
                        <Heart color="#F71834" />
                      </HearthContainer>
                    ) : (
                      <CardText>{item.description}</CardText>
                    )}
                  </FlippedItem>
                </ReactCardFlip>
              </>
            ))}
        </MemberContainer>
      </InnerContainer>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  padding: 40px;
  background-color: #fef4ea;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
    padding: 10px;
  }
`;

const InnerContainer = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin: auto;

  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
    padding: 5px;
  }
`;

const HearthContainer = styled.div`
  width: 100%;
  height: 90%;
  margin-bottom: 10%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Text = styled.p`
  width: 800px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0px;
  white-space: pre-line;
  color: #333333;
  margin-top: 16px;
  text-align: center;
  margin: auto;
  margin-bottom: 32px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  display: inline-block;

  margin-top: 0px;
  font-family: "Nunito Sans";
  font-weight: 600;
  font-size: 46px;
  text-align: center;
  margin-top: 5px;
  color: #333333;
  margin-bottom: 16px;
`;

const SmallTitle = styled.h3`
  display: inline-block;

  margin-top: 0px;
  font-family: "Nunito Sans";
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  margin-top: 5px;
  color: #333333;
  margin-bottom: 16px;
`;
const MemberContainer = styled.div`
  width: 1200px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fef4ea;

  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 700px;
    margin-top: 180px;
  }

  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
    padding: 0;
    flex-direction: column;
    align-items: center;
  }
`;

const SliderItem = styled.div`
  width: 375px;
  height: 294px;
  box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
  margin-bottom: 40px;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: center;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 320px;
    min-width: 100%;
    margin: auto;
    margin-top: 20px;
    margin-left: 0;
    height: 350px;
  }
`;

const FlippedItem = styled.div`
  width: 375px;
  height: 294px;
  box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
  margin-bottom: 40px;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 320px;
    min-width: 100%;
    margin: auto;
    margin-top: 20px;
    margin-left: 0;
    height: 350px;
  }
`;

const Picture = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
`;

const Name = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #333333;
`;

const TeamFunction = styled.p`
  width: 200px;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #666666;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
`;

const CardText = styled.p`
  width: 100%;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #333333;
  margin-top: 0px;
  margin-bottom: 0px;
`;
export default Members;
