import React from "react";
import overons from "../../images/over-ons.jpeg";
import { FormattedMessage, Link } from "gatsby-plugin-intl";
import styled from "styled-components";

function About(props) {
  return (
    <Overons>
      <InnerContainer>
        <InfoColumn>
          <h2>
            <FormattedMessage id="vision" defaultMessage="Over ons" />
          </h2>
          <p>{props.summary}</p>
          <StyledLink to={`/vision`}>
            <FormattedMessage id="readMore" defaultMessage="Lees meer" />
          </StyledLink>
        </InfoColumn>
        <StyledImg src={overons} alt="overons" />
      </InnerContainer>
    </Overons>
  );
}

const InnerContainer = styled.div`
  width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (min-width: 275px) and (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
  }
`;

const InfoColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledImg = styled.img`
  height: 100%;
  width: 50%;
`;

const StyledLink = styled(Link)`
  width: 100%;
  display: flex;

  color: #337ab7;
  text-decoration: none;
  font-family: "Nunito Sans";
  @media only screen and (min-width: 275px) and (max-width: 1100px) {
    display: none;
  }
`;

const Overons = styled.div`
  background-color: #fef4ea;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 550px;
  h2 {
    display: flex;
    padding-top: 20px;
    padding-left: 0px;
    width: 450px;
    height: 44px;
    font-family: "Nunito Sans";
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
  }
  p {
    padding-top: 10px;
    margin-left: 0px;
    width: 450px;
    font-family: "Nunito Sans";
    line-height: 1.67;
    font-size: 18px;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
  }
  h3 {
    width: 450px;
    margin-top: 10px;
    font-family: "Nunito Sans";
    font-size: 18px;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
    font-weight: 600;
    cursor: pointer;
  }
  @media only screen and (min-width: 275px) and (max-width: 1100px) {
    background: url(${overons}) no-repeat center center;
    background-size: cover;
    margin-top: 20px;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div {
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    h2 {
      width: 102px;
      height: 38px;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: center;
      color: #ffffff;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      position: relative;
      margin-top: 0px;
    }
    p {
      width: 290px;
      height: 230px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.67;
      letter-spacing: 0;
      font-style: normal;
      font-stretch: normal;
      margin-top: 40px;
      text-align: center;
      color: #ffffff;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }
    h3 {
      width: 114px;
      height: 42px;
      border-radius: 3px;
      background-color: #f58320;
      font-size: 16px;
      padding-top: 12px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: center;
      color: #ffffff;
      position: relative;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      vertical-align: middle;
    }
    img {
      display: none;
    }
  }
`;

export default About;
